import Vue from 'vue'
import Router from 'vue-router'

// 路由懒加载：分组名称一样则会打包到同一个js文件中 这样一个组件加载了其他的也会加载

const Login = () => import(/* webpackChunkName: "login_home_welcome" */ './components/Login.vue')
const Home = () => import(/* webpackChunkName: "login_home_welcome" */ './components/Home.vue')//管理员首页

const Welcome = () => import(/* webpackChunkName: "login_home_welcome" */ './components/Welcome.vue')
const RotationChartSettings = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/RotationChartSettings/RotationChartSettings.vue')//轮播图设置
const ArticleSettings = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/RotationChartSettings/ArticleSettings.vue')//文章设置
const ClinicManagement = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/RotationChartSettings/ClinicManagement.vue')//诊所管理
// 资料信息
const PhysicalExamination = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/information/PhysicalExamination.vue')//体检信息
const EquipmentManagement = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/information/EquipmentManagement.vue')//设备管理
const ExceptionList = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/information/ExceptionList.vue')//异常列表
const TaskList = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/information/TaskList.vue')//审核任务列表
//用户管理
const Users = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/user/Users.vue')  //用户列表
const HospitalList = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/user/HospitalList.vue')  //医院列表
const DoctorList = () => import(/* webpackChunkName: "Users_Rights_Roles" */ './components/user/DoctorList.vue')  //医生列表

//服务管理
const Cooperation = () => import('./components/ServiceManagement/Cooperation.vue')//商务合作
const Feedback = () => import('./components/ServiceManagement/Feedback.vue')//意见反馈

//数据采集
const ScreeningList = () => import('./components/DataAcquisition/ScreeningList.vue')//意见反馈


//商品管理
const Params = () => import(/* webpackChunkName: "Cate_Params" */ './components/goods/Params.vue')
const GoodsList = () => import(/* webpackChunkName: "GoodsList_Add" */ './components/goods/List.vue')
const Add = () => import(/* webpackChunkName: "GoodsList_Add" */ './components/goods/Add.vue')
const Order = () => import(/* webpackChunkName: "Order_Report" */ './components/order/Order.vue')//订单列表
const Devices = () => import(/* webpackChunkName: "Order_Report" */ './components/order/devices.vue')//设备列表
const RefundList = () => import(/* webpackChunkName: "Order_Report" */ './components/order/RefundList.vue')//退款列表
const DataAcquisition = () => import(/* webpackChunkName: "Order_Report" */ './components/DataAcquisition/DataAcquisition.vue')//数据采集

//报表统计
const ReportStatistics = () => import(/* webpackChunkName: "Order_Report" */ './components/ReportStatistics/ReportStatistics.vue')//报表统计


Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', redirect: '/login' }, /* 重定向 */
    { path: '/login', component: Login },

    { path: '/home',component: Home,redirect: '/welcome',
 
      children: [
        { path: '/welcome', component: Welcome },
        { path: '/RotationChartSettings', component: RotationChartSettings },//轮播图设置
        { path: '/ArticleSettings', component: ArticleSettings },//文章设置
        { path: '/ClinicManagement', component: ClinicManagement },//诊所管理
        // 资料信息
        { path: '/PhysicalExamination', component:PhysicalExamination},//体检信息
        { path: '/EquipmentManagement', component:EquipmentManagement},//设备管理
        { path: '/ExceptionList', component:ExceptionList},//设备管理
        { path: '/TaskList', component:TaskList},//医生审核

        //服务管理
        { path: '/Cooperation', component: Cooperation },//商务合作
        { path: '/Feedback', component: Feedback },//商务合作

        //用户管理
        { path: '/users', component: Users },  //用户列表
        { path: '/HospitalList', component: HospitalList }, //医院列表
        { path: '/DoctorList', component: DoctorList }, //医生列表


        // { path: '/rights', component: Rights },
        // { path: '/roles', component: Roles },

        //商品管理
        { path: '/params', component: Params },
        { path: '/goods', component: GoodsList },
        { path: '/goods/add', component: Add },
        { path: '/orders', component: Order },//订单列表
        { path: '/devices', component: Devices },//设备列表
        { path: '/RefundList', component: RefundList },//退款列表
        
        
        //报表统计
        { path: '/ReportStatistics', component: ReportStatistics },  //报表统计
        { path: '/dataAcquisition', component: DataAcquisition },//数据采集
        { path: '/ScreeningList', component: ScreeningList },//数据采集
        
        // { path: '/advertisement', component: Advertisement },   //用户管理
        // { path: '/reports', component: Report }
      ]
    }
  ]
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router /* 因为要挂载导航守卫 所以不能实时导出 */
